import EmailsVerificationService from '@/services/emails-verification.service.js'
import StepTwo from './StepTwo/StepTwo.vue'
import EventBus from '@/util/EventBus.js'
import getEnv from '@/util/env'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import { AwsEmailDnsProvider } from './AwsEmailDnsProvider'

export default {
  name: 'EmailsVerificationAssistant',
  components: {
    HeaderTopDashboard,
    StepTwo,
  },
  data: function () {
    return {
      domainObject: {
        stepperStep: 1,
        domains: [],
        awsStatuses: [],
        domain: {
          domain: '',
          predefined: false,
          verified: false,
        },
        newDomain: {
          domain: '',
        },
        email: {
          email: '',
          user: '',
        },
        isNewDomain: false,
        loadingContinueState: false,
        showSelectVersion: false,
        emailVersionV1: false,
      },
      showSelectVersion: false,
      emailVersionV1: false,
      panel: false,
    }
  },

  computed: {
    disabledFirstStep () {
      return (!this.domainObject.isNewDomain && !this.domainObject.domain.domain) || (this.domainObject.isNewDomain && !this.domainObject.newDomain.domain)
    },
    canNrsSmtp () {
      return this.$store.getters['auth/getUser'].enable?.nrssmtp
    },
    canEmailV2 () {
      return this.$store.getters['auth/getUser'].enable?.emailV2
    },
    canEmailV1 () {
      return this.$store.getters['auth/getUser'].enable?.emailV1
    },
    selectedProvider () {
      if (this.canEmailV1) {
        return AwsEmailDnsProvider.SES_ID
      } else if (this.canEmailV2) {
        return AwsEmailDnsProvider.ROUTE53_ID
      } else {
        return this.emailVersionV1 ? AwsEmailDnsProvider.SES_ID : AwsEmailDnsProvider.ROUTE53_ID
      }
    },
  },
  mounted () {
    this.setCreationData()
  },
  methods: {

    setEmptyDomain () {
      this.setDefaultEmptyDomain()
      this.domainObject.isNewDomain = true
    },

    setDefaultEmptyDomain () {
      this.domainObject.domain = {
          domain: '',
          predefined: false,
          verified: false,
      }
    },

    showDomainExistErrors (error, ctx) {
      let message = ''
      switch (error) {
        case 1:
          message = ctx
            .$t('No has indicado un nombre de dominio válido')
            .toString()
          break
        case 2:
          message = ctx
            .$t('El dominio indicado no es un dominio válido, Ejemplo: http://midominio').toString() + '.' + 'com'
          break
        case 3:
          message = ctx
            .$t('Ha ocurrido un error en la validación del dominio')
            .toString()
          break
        case 4:
          message = ctx
            .$t(
              'No puedes crear el dominio, existe alguna dirección de respuesta configurada que pertenece a este dominio',
            )
            .toString()
          break
        case 5:
          message = ctx
            .$t('Ya existe un dominio con el nombre indicado')
            .toString()
          break
      }
      EventBus.$emit('showAlert', 'danger', message)
    },

    showAddDomainErrors (error, ctx) {
      let message = ''
      switch (error) {
        case 1:
          message = ctx
            .$t('No has indicado un nombre de dominio válido')
            .toString()
          break
        case 2:
          message = ctx
            .$t('El dominio indicado tiene un formato inválido')
            .toString()
          break
        case 3:
          message = ctx.$t('El dominio indicado ya ha existe').toString()
          break
        case 4:
          message = ctx
            .$t('Error en la validación del dominio indicado')
            .toString()
          break
        case 5:
          message = ctx
            .$t('Ha habido un error al verificar el dominio indicado')
            .toString()
          break
      }
      EventBus.$emit('showAlert', 'danger', message)
    },

    showIsAvailableEmailErrors (error, ctx) {
      let message = ''
      switch (error) {
        case 1:
          message = ctx
            .$t('Ha ocurrido un error en la validación de los datos')
            .toString()
          break
        case 2:
          message = ctx
            .$t('El email indicado tiene un formato no válido')
            .toString()
          break
        case 3:
          message = ctx.$t('El email indicado ya existe')
          .toString()
          break
      }

      EventBus.$emit('showAlert', 'danger', message)
    },

    showAddEmailAddressError (error, ctx) {
      let message = ''
      switch (error) {
        case 1:
          message = ctx
            .$t('Ha ocurrido un error en la validación de los datos')
            .toString()
          break
        case 2:
          message = ctx
            .$t('El dominio, para el que intentas crear esta cuenta, no existe')
            .toString()
          break
        case 3:
          message = ctx.$t('El dominio, para el que intentas crear esta cuenta, no está disponible')
          .toString()
          break
        case 4:
          message = ctx
            .$t('El email indicado ya existe')
            .toString()
          break
        case 5:
          message = ctx
            .$t('Ha ocurrido un error en la verificación del email')
            .toString()
          break
        case 6:
          message = ctx
            .$t('Ha ocurrido un error añadiendo el email indicado')
            .toString()
          break
        case 7:
          message = ctx
            .$t('Ha ocurrido un error en la verificación del email')
            .toString()
          break
      }
      EventBus.$emit('showAlert', 'danger', message)
    },

    addEmail () {
      this.domainObject.loadingContinueState = true
      const that = this
      EmailsVerificationService.isAvailableEmail({
        dnsProvider: that.domainObject.domain.dnsProvider,
        domain: that.domainObject.domain.domain,
        mailbox: that.domainObject.email.user,
      }).then((result) => {
        const data = result

        if (data.error) {
          that.domainObject.loadingContinueState = false
          this.showIsAvailableEmailErrors(data.error, that)
          return
        }

        EmailsVerificationService.insertEmailAddress({
          dnsProvider: that.domainObject.domain.dnsProvider,
          mailbox: that.domainObject.email.user,
          domainId: that.domainObject.domain.domainData.domain.id,
        }).then((result) => {
          if (!result) {
            return
          }
          if (result.error) {
            that.domainObject.loadingContinueState = false
            this.showAddEmailAddressError(data.error, that)
            return
          }
          that.email = data.email
          setTimeout(() => {
            that.domainObject.stepperStep = 4
            that.domainObject.loadingContinueState = false
          }, 500)
        })
      })
    },

    checkDomainExists () {
      this.domainObject.loadingContinueState = true
      const dnsProvider = this.selectedProvider
      // const dnsProvider = AwsEmailDnsProvider.ROUTE53_ID
      const that = this
      const domainData = {
        dnsProvider: dnsProvider,
        domain: this.domainObject.newDomain.domain,
      }

      EmailsVerificationService.checkDomainExists(domainData).then(
        (response) => {
          if (response.error) {
            this.showDomainExistErrors(response.error, that)
            this.domainObject.loadingContinueState = false
            return
          }

          EmailsVerificationService.addDomain({
            dnsProvider: dnsProvider,
            domain: response.domain,
          }).then(
            (response) => {
              if (response.error) {
                that.showAddDomainErrors(response.error, that)
                that.domainObject.loadingContinueState = false
                return
              }

              that.domainObject.domain = {
                dnsProvider: dnsProvider,
                domain: response.domain.domain,
                predefined: false,
                verified: false,
                domainData: {
                  domain: response.domain,
                  dns: response.dns,
                },
              }
              that.domainObject.domains.push({
                dnsProvider: dnsProvider,
                domain: response.domain.domain,
                verified: false,
              })
              that.$forceUpdate()

              setTimeout(() => {
                that.domainObject.stepperStep = 2
                that.domainObject.loadingContinueState = false
              }, 500)
            },
          )
        },
      )
    },

    clickDomain () {
      this.domainObject.isNewDomain = false
      this.showSelectVersion = false
      this.emailVersionV1 = false
    },

    selectDomain () {
      this.showSelectVersion = false
      if (this.domainObject.isNewDomain) {
        this.checkDomainExists()
      } else {
        this.getDomain()
      }
    },

    setCreationData () {
      EmailsVerificationService.getCreationData().then((response) => {
        this.domainObject.domains = response.domains
        this.domainObject.awsStatuses = response.awsStatuses
        this.setDefault()
      })
    },

    setDefault () {
      let predefined = this.domainObject.domains.filter(d => d.predefined)
      if (!predefined.length) {
        return
      }

      predefined = predefined[0]
      this.domainObject.domain.domain = predefined.domain
      this.domainObject.domain.predefined = predefined.predefined
      this.domainObject.domain.verified = predefined.verified
    },

    getDomain () {
      this.domainObject.loadingContinueState = true
      EmailsVerificationService.getDomain({
        dnsProvider: this.domainObject.domain.dnsProvider,
        domain: this.domainObject.domain.domain,
      }).then((domainData) => {
        // console.log('domainData', domainData)
        this.domainObject.domain.domainData = domainData
        this.$forceUpdate()

        if (this.domainObject.domain.dnsProvider === AwsEmailDnsProvider.SES_ID) {
          if (
            this.domainObject.domain.domainData.domain.status_id ===
            this.domainObject.awsStatuses.Success
          ) {
            setTimeout(() => {
              this.domainObject.stepperStep = 3
              this.domainObject.loadingContinueState = false
            }, 500)
          } else {
            setTimeout(() => {
              this.domainObject.stepperStep = 2
              this.domainObject.loadingContinueState = false
            }, 500)
          }
        } else {
          setTimeout(() => {
            this.domainObject.stepperStep = 2
            this.domainObject.loadingContinueState = false
          }, 500)
        }
      })
    },

    exportPdf () {
      const dnsProvider = this.domainObject.domain.dnsProvider
      const domainId = this.domainObject.domain.domainData.domain.id
      const user = JSON.parse(localStorage.getItem('user'))
      window.open(getEnv('backend_endpoint') + 'api/account/emails/dns-pdf/' + dnsProvider + '/' + domainId + '?token=' + user.token, '_blank')
    },

    getDnsProvider (domain) {
      return domain.dnsProvider === AwsEmailDnsProvider.SES_ID ? 'v1' : 'v2'
    },

    isV1 (domain) {
      return domain.dnsProvider === AwsEmailDnsProvider.SES_ID
    },

    isV2 (domain) {
      return domain.dnsProvider === AwsEmailDnsProvider.ROUTE53_ID
    },
  },
}
